<template>
    <div class="w-100 d-flex">
        <vs-input
            id="barcode"
            v-model="barcode"
            class="w-100"
            border
            :state="stateEnabled"
            shadow
            label="Código de barras"
            :disabled="!enabled"
            :loading="isLoading"
            @blur="verifyBarcode"
            @input="inputBarcode"
        >
            <template #message-warn>
                <span v-if="isDuplicated">
                    Código de barras ya registrado
                </span>
            </template>
        </vs-input>
        <barcode-scanner-button v-if="enabled" @change="change" />
        <div>
            <vs-dialog v-model="isOpenModal" scroll overflow-hidden auto-width>
                <template #header>
                    <div class="p-2">
                        <span>
                            Referencias registradas con el código de barras
                            {{ barcode }}
                        </span>
                    </div>
                </template>
                <div class="con-content border rounded border-info py-2 px-4">
                    <div class="d-flex flex-column align-items-center">
                        <reference-item
                            v-for="reference in references"
                            :key="reference.id"
                            :reference="reference"
                        />
                    </div>
                </div>
            </vs-dialog>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ReferenceItem from "../references/ReferenceItem.vue";
import BarcodeScannerButton from "./BarcodeScannerButton.vue";

export default {
    name: "BarcodeScannerInput",
    components: { BarcodeScannerButton, ReferenceItem },
    model: {
        prop: "itemCurrent",
        event: "change"
    },
    props: {
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        },
        check: {
            type: Boolean,
            required: false,
            default: () => false
        },
        referenceId: {
            type: Number,
            required: false,
            default: () => 0
        },
        itemCurrent: {
            type: String,
            required: true,
            default: () => ""
        }
    },
    data: () => ({
        isDuplicated: false,
        barcode: "",
        isLoading: false,
        references: [],
        isOpenModal: false
    }),
    computed: {
        ...mapGetters("control", ["backgroundColor", "stateInputDark"]),
        stateEnabled() {
            return this.enabled ? this.stateInputDark : "";
        }
    },
    watch: {
        itemCurrent(value) {
            this.barcode = value;
        }
    },
    mounted() {
        this.barcode = String(this.itemCurrent);
    },
    methods: {
        ...mapActions("products", ["listReferenceByBarcode"]),
        change(barcode) {
            if (barcode === this.barcode) {
                return;
            }
            this.barcode = barcode;
            this.$emit("change", this.barcode);
            if (barcode !== "") {
                this.verifyBarcode();
            }
        },
        inputBarcode() {
            this.$emit("change", this.barcode);
        },
        async verifyBarcode() {
            if (this.check && this.barcode) {
                this.isDuplicated = false;
                this.isLoading = true;
                try {
                    this.references = await this.listReferenceByBarcode({
                        barcode: this.barcode
                    });
                    const isDuplicated = this.references.some(
                        (item) => this.referenceId !== item.id
                    );
                    this.isDuplicated = isDuplicated;
                    this.isOpenModal = isDuplicated;
                    this.isLoading = false;
                } catch (error) {
                    this.isLoading = false;
                    console.error(this.backgroundColor);
                    this.$swal.fire({
                        background: this.backgroundColor,
                        title: error.title,
                        text: error.message,
                        icon: error.icon
                    });
                }
            }
        }
    }
};
</script>
