<template>
    <div class="border-bottom border-info">
        <div class="d-flex flex-wrap">
            <div
                class="col-md-3 col-4 d-flex justify-content-center align-items-center py-3"
            >
                <button
                    class="w-28 h-28 p-0"
                    @click="goToMainPage(reference.slug)"
                >
                    <img
                        class="w-100 h-100"
                        :src="reference.images[0].url | image"
                        :alt="reference.images[0].alt"
                        loading="lazy"
                    />
                </button>
            </div>
            <div
                class="col-md-9 col-8 d-flex flex-column justify-content-around py-3 px-1"
            >
                <div class="d-flex justify-content-between">
                    <router-link
                        :to="`/app/references/detail/${reference.productId}`"
                        class="btn-a p-0"
                    >
                        {{ reference.name }} / # {{ reference.id }}
                    </router-link>
                </div>

                <div class="d-flex flex-wrap">
                    <div
                        class="col-5 d-flex flex-md-column justify-content-between mr-1"
                    >
                        <strong class="text-success">SKU </strong>
                        <div class="d-flex align-items-center">
                            <small>
                                {{ reference.sku }}
                            </small>
                        </div>
                    </div>
                    <div
                        class="col-5 d-flex flex-md-column justify-content-between mr-1"
                    >
                        <strong class="text-success">Precio final </strong>
                        <small>
                            {{ reference.priceOffer | money }}
                        </small>
                    </div>
                    <div
                        class="col-5 d-flex flex-md-column justify-content-between mr-1"
                    >
                        <strong class="text-success">
                            Precio sin descuento
                        </strong>
                        <small>
                            {{ reference.price | money }}
                        </small>
                    </div>
                    <div
                        class="col-5 d-flex flex-md-column justify-content-between mr-1"
                    >
                        <strong class="text-success">Color </strong>
                        <small class="text-capitalize">
                            {{ reference.color }}
                        </small>
                    </div>
                    <div
                        class="col-5 d-flex flex-md-column justify-content-between mr-1"
                    >
                        <strong class="text-success">Tamaño </strong>
                        <small class="text-capitalize">
                            {{ reference.size }}
                        </small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DEFAULT_IMAGE_URL } from "@/common/constants";

export default {
    name: "ReferenceItem",
    props: {
        reference: {
            type: Object,
            required: true,
            default: () => ({
                productId: 1,
                id: 1,
                sku: "UEUM11",
                name: "Sudadera colegio montessori 2",
                slug: "sudadera-colegio-montessori-2",
                priceOffer: 150000,
                price: 150000,
                images: [
                    {
                        id: 1,
                        url: DEFAULT_IMAGE_URL,
                        alt: "Chaqueta de sudadera colegio montessori 2 kennedy Bogotá."
                    }
                ]
            })
        }
    },
    methods: {
        goToMainPage(slug) {
            window.open(`${slug}`, "_blank").focus();
        }
    }
};
</script>
